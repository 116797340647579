<script setup>

</script>

<template>
    <h3 class="
        block

        text-gray-900

        font-bold
        text-2xl
    "><slot /></h3>
</template>
